export let GLOBAL = {
    //url: 'http://localhost:52946/api/'

     // API PROD
   // url: 'https://oceanswebpageapi.oceanscode.com/api/',

    // API DEMO
    // url: 'http://www.demo.apioceanswebpage.oceanscode.com/api/'

    // API DEMO AWS
    url: 'http://demo.api.oceanscode.com/api/'
 }
